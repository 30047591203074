<template>
  <!-- <v-container class="menuBottom">
    <v-container class="container_fichas"> -->
  <!-- <v-icon
        class="iconExpand"
        @click="openF()"
        v-show="mostrarF == false"
        >mdi-arrow-collapse-vertical</v-icon> -->
  <v-container class="dientesTabs">
    <!-- <v-icon
                class="iconExpand"
                @click="openF()"
                v-show="mostrarF == true"
                >mdi-arrow-collapse-vertical</v-icon> -->
    <v-row>
      <v-col v-show="periodo != null" class="noP" cols="12" md="12">
        <v-toolbar class="paddingFichas noP" dense flat>
          <div
            v-if="mostrarF == true"
            style="overflow-x: auto; white-space: nowrap"
          >
            <v-container
              class="menuBarDoc"
              :id="itemM"
              v-for="(itemM, indexM) in filtroM"
              :key="indexM"
              @click="filtrarM(itemM)"
            >
              <span class="textMenu">{{ itemM }}</span>
            </v-container>
          </div>
        </v-toolbar>
      </v-col>

      <v-col class="noP" cols="12" md="12">
        <v-toolbar class="paddingFichasP noP" dense flat>
          <v-btn small dark color="primary" elevation="0" icon @click="openF()">
            <v-icon class="iconExpand">{{
              mostrarF == true
                ? "mdi-arrow-collapse-vertical"
                : "mdi-arrow-expand-vertical"
            }}</v-icon>
          </v-btn>
          <div
            v-if="mostrarF == true"
            style="overflow-x: auto; white-space: nowrap"
          >
            <v-container
              class="menuBarDoc"
              :id="itemM"
              v-for="(itemM, indexM) in listaPeriodos"
              :key="indexM"
              @click="filtrarP(itemM)"
            >
              <span class="textMenu">{{ itemM }}</span>
            </v-container>
          </div>
        </v-toolbar>
      </v-col>
    </v-row>
  </v-container>

  <!-- <v-row v-if="mostrarF == true"> -->
  <!-- <v-col cols="12" md="1" >
                <v-icon
                class="iconExpand"
                @click="openF()"
                v-show="mostrarF == true"
                >mdi-arrow-collapse-vertical</v-icon>
            </v-col> -->
  <!-- <v-col v-show="periodo != null" class="noP" cols="12" md="11">
            <v-container>
                <v-toolbar class="paddingFichas noP" dense  flat>
                    <div style="overflow-x:auto; white-space: nowrap;">
                        <v-container
                        class="menuBarDoc"
                        :id="itemM"
                        v-for="itemM, indexM in filtroM"
                        :key="indexM"
                        @click="filtrarM(itemM) "
                        >
                        <span class="textMenu">{{itemM}}</span>
                        </v-container>
                    </div>
            </v-toolbar>
            </v-container>
        </v-col> -->
  <!-- <v-col class="noP" cols="12" md="11">
            <v-toolbar class="paddingFichasP noP" dense flat>
                <div style="overflow-x:auto; white-space: nowrap;">
                    <v-container
                    class="menuBarDoc"
                    :id="itemM"
                    v-for="itemM, indexM in listaPeriodos"
                    :key="indexM"
                    @click="filtrarP(itemM) "
                    >
                    <span class="textMenu">{{itemM}}</span>
                    </v-container>
                </div>
            </v-toolbar>
        </v-col> -->
  <!-- <br /> -->
  <!-- </v-row> -->
  <!-- </v-container>
    </v-container> -->
</template>

<script>
import firebase from "firebase";
import { host } from "../Tools/variables";
import axios from "axios";
export default {
  name: "ToolBarNavFooter",
  props: ["periodo", "materia"],
  data() {
    return {
      mostrarF: true,
      filtroM: [],
      listaPeriodos: [],
      search: {
        periodo: null,
        materia: null,
      },
    };
  },
  watch: {
    materia: function (v) {
      this.search["materia"] = v;
      this.selectedMB(this.filtroM, v);
    },
    periodo: function (v) {
      this.search["periodo"] = v;
      this.selectedMB(this.listaPeriodos, v);
    },
    search: {
      deep: true,
      handler(a) {
        this.$emit("edit-tabs-search", a);
      },
    },
  },
  mounted() {
    this.cursosProfes();
  },
  methods: {
    cursosProfes() {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "cursos_habilitados");
          axios.post(host + "/cursos", fd).then((result) => {
            let data = result.data;
            this.filtroM = data.listaMaterias;
            this.listaPeriodos = data.listaPeriodos;
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },
    filtrarP(data) {
      this.periodo = data;
    },
    filtrarM(data) {
      this.materia = data;
    },
    openF() {
      this.mostrarF = !this.mostrarF;
      this.selectedMB(this.listaPeriodos, this.periodo);
      this.selectedMB(this.filtroM, this.materia);
    },
    selectedMB(data, name) {
      var l = data;
      for (var i = 0; i < l.length; i++) {
        if (l[i] == name) {
          document.getElementById(name).style.borderTop = "4px solid #0D47A1";
        } else {
          document.getElementById(l[i]).style.borderTop = "0.5px solid #0D47A1";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dientesTabs {
  display: inline;
  margin: 0 0 0 15px !important;
}

.menuBottom {
  position: absolute;
  padding: 0;
  bottom: 0px;
  z-index: 10;
  min-width: 100%;
  //   background-color: white;
}
.container_fichas {
  padding-bottom: 0;
  min-width: 100%;
  margin: 0;
}

.menuBarDoc {
  width: auto;
  height: auto;
  margin: 0;
  padding: 1px 6px 0px 6px;
  display: inline-block;
  border-radius: 8px 8px 0px 0px;
  border: 0.8px solid #0d47a1;
  border-bottom: none;
  background-color: white !important;
  /* margin-left: 5px;  */
}
.menuBarDoc:hover {
  /* border-top: 40px solid  #005005; */
  color: #0d47a1;
  opacity: 0.6;
}

.iconExpand {
  margin-left: 5px;
  margin-right: 10px;
}

.textMenu {
  color: #0d47a1 !important;
  font-weight: 500;
  font-size: 12px !important;
}

.noP {
  padding: 0px !important;
  margin: 0px !important;
}
.paddingFichas {
  height: 25px !important;
  background-color: transparent !important;
}

.paddingFichasP {
  height: 38px !important;
  padding-left: -20px !important;
  margin-left: -20px !important;
  background-color: transparent !important;
}
</style>