// Función para selecionar las rows

function infoSelected(grid, nameId) {
    if (grid.selected == null) {
        return null;
    }else{
    var rowSelected = {
        selectedRow: '',
        field: null,
        id: '',
    } 
    var idAbuscar = Object.values(grid.selected)
        grid.value.forEach((field, k) => {
        if (field['$id'] == idAbuscar){
            rowSelected.selectedRow = k
            rowSelected.field = field
            rowSelected.id = field[nameId]
            } 
        }) 
        return rowSelected;
    }
    
}
export default infoSelected
