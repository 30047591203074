<template>
  <v-container class="contenedor-Stools">
    <v-row class="rowNop">
      <v-col
        class="colNop"
        cols="12"
        md="3"
        v-for="(item, c) in items"
        v-bind:key="item.value + c"
      >
        <v-autocomplete
          @change="click"
          @click="blur"
          v-model="item.value"
          :items="item.items"
          chips
          clearable
          hide-details
          :label="item.label"
          rounded
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title
                >Este {{ item.label.toLowerCase() }} no
                existe</v-list-item-title
              >
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import firebase from "firebase";
// import { host } from "../Tools/variables";
// import axios from "axios";

export default {
  components: {},
  name: "ToolBarNavList",
  props: ["items"],

  methods: {
    blur() {
      this.$emit("toggle", "blur");
    },
    click() {
      this.$emit("toggle", this.items);
    },
  },
};
</script>

<style>
.contenedor-Stools {
  min-width: 100%;
  margin: 0;
  width: 100%;
  display: flex;
  padding: 0 !important;
  border: 1px #8884 solid;
  z-index: 100;
}
</style>