<template>
  <v-container class="contenedor-main">
    <v-row>
      <tool-bar-nav-list
        :items="[
          { items: filtroM, value: curso.materia, label: 'Materias' },
          { items: listaPeriodos, value: curso.periodo, label: 'Periodos' },
        ]"
        v-on:toggle="searchC($event)"
      ></tool-bar-nav-list>
    </v-row>
    <template>
      <v-row>
        <tool-bar-nav
          v-if="jsondata != null"
          :pageRow="{ value: pageLengthPer, type: 'clientesHeigth' }"
          :editColsBottom="{ badgeText: 'Editar cols' }"
          :dataColumns="{
            colsShow: columnasMostrar,
            colsList: listaColumnas,
            endpointEdit: 'editar',
            materia: curso.materia,
            periodo: curso.periodo,
          }"
          :refreshBottom="{ badgeText: 'Reestablecer cols' }"
          :copyRowBottom="{ badgeText: 'Copiar usuario' }"
          :exportBottom="{
            badgeText: 'Exportar tabla',
            nameDoc: 'Clientes',
          }"
          :addColsBottom="{
            badgeText: 'Agregar columnas nuevas',
            mat: curso.materia,
            per: curso.periodo,
          }"
          :deleteNewColsBottom="{
            badgeText: 'Eliminar columnas',
            colsExtra: listaColumnasE,
            mat: curso.materia,
            per: curso.periodo,
            jsonData: jsondata,
          }"
          :organiceCols="{
            badgeText: 'Organizar columnas',
            colsExtra: listaColumnasE,
            mat: curso.materia,
            per: curso.periodo,
          }"
          :editLabelCols="{
            badgeText: 'Cambiar nombres',
            colsExtra: listaColumnasE,
            mat: curso.materia,
            per: curso.periodo,
          }"
          :editAssociate="{
            badgeText: 'Relacionar notas',
            listN: listN,
            notas: notas,
            colsExtra: listaColumnasE,
            mat: curso.materia,
            per: curso.periodo,
          }"
          :histBottom="{ show: rigthClick, data: historial }"
          :colorBottom="{ show: rigthClick }"
          :novedadesIcon="{
            badgeText:
              'Tienes ' + cantidadNotiNuevos + ' NUEVOS en tus cursos!',
            color: colorNotiNuevos,
          }"
          :gestionIcon="{
            badgeText:
              'Tienes ' + cantidadGNuevos + ' gestiones NUEVAS en tus cursos!',
            color: colorGNuevos,
          }"
          :textoActionToolBar="{ text: textoActionToolBar }"
          :selectedRow="onSelected"
          :grid="{ idGrid: $refs.grid }"
          v-on:toggle="eventosClientes($event)"
          v-on:associate-cols="rMaterias($event)"
          v-on:texto-tool="eventosTextTool($event)"
          v-on:color-item="eventosColor($event, asignarColor)"
        >
        </tool-bar-nav>
        <v-container class="contenedor-tabla" v-show="mostrarTable == true">
          <vue-excel-editor
            v-if="mt"
            v-show="mostrarTable == true"
            ref="grid"
            v-model="jsondata"
            :page="pageLengthPer"
            filter-row
            :localized-label="myLabels"
            :cell-style="metodoColorBack"
            v-on:toggle-table="activeRigthClick($event)"
            @cell-focus="getLabel"
            @select="onSelect"
            :enterToSouth="enterAbajo"
            remember
            free-select
          >
            <vue-excel-column
              field="Fecha de matrícula"
              label="Fecha de matrícula"
              type="string"
              :validate="valid"
            />
            <vue-excel-column
              field="Nombre"
              label="Nombre"
              type="string"
              :validate="valid"
            />
            <vue-excel-column
              field="Usuario"
              label="Usuario"
              type="string"
              :validate="valid"
            />
            <vue-excel-column
              field="Contraseña"
              label="Contraseña"
              type="string"
              :validate="valid"
            />
            <vue-excel-column
              field="País"
              label="País"
              type="string"
              :validate="valid"
            />
            <vue-excel-column
              field="Servidor"
              label="Servidor"
              type="number"
              :validate="valid"
            />
            <vue-excel-column
              field="Moodle"
              label="Moodle"
              type="number"
              :validate="valid"
            />
            <vue-excel-column
              field="Correo"
              label="Correo"
              type="string"
              :validate="valid"
            />
            <vue-excel-column
              field="Contraseña del correo"
              label="Contraseña del correo"
              :change="onChange"
              type="string"
            />
            <vue-excel-column
              field="Estado usuario"
              label="Estado usuario"
              type="select"
              :options="estadoU"
              :change="cambioEstadoU"
            />
            <vue-excel-column field="Respuesta" label="Respuesta" />
            <vue-excel-column field="LINK DRIVE" label="LINK DRIVE" />
            <vue-excel-column
              field="CEAD"
              label="CEAD"
              type="string"
              :validate="valid"
            />
            <vue-excel-column
              field="Carrera"
              label="Carrera"
              :validate="valid"
            />
            <vue-excel-column field="Cédula" label="Cédula" :validate="valid" />
            <vue-excel-column
              field="Tutor"
              label="Tutor"
              type="string"
              :change="onChange"
            />
            <vue-excel-column
              field="Grupo"
              label="Grupo"
              type="string"
              :change="onChange"
            />
            <vue-excel-column
              field="Observaciones"
              label="Observaciones"
              type="string"
              :validate="valid"
            />
            <vue-excel-column
              field="REENVÍO"
              label="REENVÍO"
              type="string"
              :change="onChange"
            />
            <vue-excel-column
              v-for="field in newData"
              v-bind:key="field.key"
              :type="field.type"
              :field="field.field"
              :label="field.label"
              :invisible="field.invisible"
              :change="onChange"
            />
          </vue-excel-editor>
        </v-container>
        <v-footer color="transparent" class="col-12 ma-0 pa-0">
          <tool-bar-nav-footer
            :materia="curso.materia"
            :periodo="curso.periodo"
            v-on:edit-tabs-search="toolBarFooter"
          >
          </tool-bar-nav-footer>
        </v-footer>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import firebase from "firebase";
import { host } from "./Tools/variables";
import axios from "axios";
import ToolBarNav from "./component/ToolBarNav.vue";
import infoSelected from "./Tools/itemSelected";
import ToolBarNavList from "./component/ToolBarNavList.vue";
import ToolBarNavFooter from "./component/ToolBarNavFooter.vue";
import data from "./Tools/data";

import tablesMixin from "../mixins/tablas";

export default {
  mixins: [tablesMixin],

  components: {
    ToolBarNavList,
    ToolBarNav,
    ToolBarNavFooter,
  },

  data() {
    return {
      pageLengthPer: this.$store.state.clientesHeigth,
      textoActionToolBar: [],
      newData: [],
      dialogNewColumn: false,
      label_: null,
      nombresCols: [],
      data: null,
      jsondata: [],
      indexRRow: [0],
      filtroM: [],
      listaMaterias: [],
      listaPeriodos: [],
      listaCursos: [],
      // periodo: null,
      // materia: null,
      mostrarTable: false,
      numeroCol: null,
      numeroRow: null,
      editarColumnas: false,
      listaColumnas: [],
      columnasMostrar: [],
      estadoU: [],
      dataTiposA: [],
      search: [],
      mt: false,
      respuestaAlerta: [],
      listaColumnasE: [],
      eliminarColumnas: false,
      onSelected: null,
      colorNotiNuevos: "#999",
      cantidadNotiNuevos: 0,
      colorGNuevos: "#999",
      cantidadGNuevos: 0,
      listN: [], //  relacion almacenada
      notas: [], //lista de las notas
      // click derecho
      rigthClick: false,
      // color
      asignarColor: {
        name: null,
        color: null,
        idItem: null,
      },
      // tiene personalización de cols?
      haveCellsColor: true,
      // hitorial
      historial: {
        nombreCol: null,
        id: null,
      },
      curso: {
        materia: null,
        periodo: null,
      },
    };
  },

  watch: {
    curso: {
      deep: true,
      handler(a) {
        var obj = Object.values(a);
        var ok = obj.every((e) => e != null);
        if (ok) {
          console.log(a);
          this.buscar();
          document.title =
            "Estudiantes Docente" +
            "/" +
            this.curso.materia +
            " - " +
            this.curso.periodo;
        } else {
          this.mostrarTable = false;
          this.$store.state.clientesFiltro = null;
        }
      },
    },

    dataTiposA: function () {
      for (var f = 0; f < this.$store.state.tiposAlerta.length; f++) {
        this.estadoU.push(this.$store.state.tiposAlerta[f]["nombre"]);
      }
    },
  },

  async created() {
    this.listaCursos = await data.dataList(
      "/cursos",
      "lista",
      "listar",
      "cursos"
    );
    this.dataTiposA = await data.dataList(
      "/tiposAlerta",
      "lista",
      "listar",
      "tiposAlerta"
    );

    this.novedadesGet();
  },

  async mounted() {
    this.listaCursos = await data.dataList(
      "/cursos",
      "lista",
      "listar",
      "cursos"
    );
    document.title = "Estudiante Docente";
    this.cursosProfes();
    this.cursosGet();
    if (this.$store.state.clientesFiltro != null) {
      var info = this.$store.state.clientesFiltro.split(" - ");
      this.curso.periodo = info[1];
      this.curso.materia = info[0];
    }
  },

  methods: {
    novedadesGet() {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          axios.post(host + "/novedades", fd).then((result) => {
            if (result.data.text == "Error, dirección IP bloqueada") {
              this.$alert("IP bloqueada por el administrador");
              firebase.auth().signOut();
            }
            let data = result.data.info;
            this.warnigs(data);
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    warnigs() {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "solonuevos");
          axios.post(host + "/novedades", fd).then((result) => {
            let data = result.data;
            if (data.text == "OK") {
              if (data.cantidad != 0) {
                this.cantidadNotiNuevos = data.cantidad;
                this.colorNotiNuevos = "orange";
              }
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "pendientes");
          axios.post(host + "/gestiones", fd).then((result) => {
            let data = result.data;
            if (data.text == "OK") {
              if (data.cantidad != 0) {
                this.cantidadGNuevos = data.cantidad;
                this.colorGNuevos = "red";
              }
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    async cursosProfes() {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "cursos_habilitados");
          axios.post(host + "/cursos", fd).then((result) => {
            if (result.data.text == "Error, dirección IP bloqueada") {
              this.$alert("IP bloqueada por el administrador");
              firebase.auth().signOut();
            }
            let data = result.data;
            this.filtroM = data.listaMaterias;
            this.listaPeriodos = data.listaPeriodos;
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    async cursosGet() {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "listar");
          axios.post(host + "/cursos", fd).then((result) => {
            let data = result.data.lista;
            this.listaCursos = data;
            for (var i = 0; i < data.length; i++) {
              this.search.push(data[i]["cursoBase"] + "-" + data[i]["periodo"]);
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    selected(data, name) {
      var l = data;
      for (var i = 0; i < l.length; i++) {
        if (l[i] == name) {
          document.getElementById(name).style.borderTop = "4px solid #0D47A1";
        } else {
          document.getElementById(l[i]).style.borderTop = "0.8px solid #0D47A1";
        }
      }
    },

    async buscar() {
      this.$store.state.clientesFiltro =
        this.curso.materia + " - " + this.curso.periodo;
      this.textoActionToolBar = [];
      this.listaColumnas = [];
      this.columnasMostrar = [];
      this.mostrarTable = false;
      this.mt = false;
      this.newData = this.indexRRow[0] = 0;
      this.listN = [];
      var dataRelacion = this.listaCursos.find(
        (e) =>
          e.cursoBase == this.curso.materia && e.periodo == this.curso.periodo
      );
      this.listN = dataRelacion ? dataRelacion.relacionNotas : [];
      await this.cargueColors();
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "lista_estudiantes");
          fd.append("periodo", this.curso.periodo);
          fd.append("materia", this.curso.materia);
          axios
            .post(host + "/cursos", fd)
            .then((result) => {
              let data = result.data;
              if (
                data.text ==
                "Error, no se puede encontrar el curso seleccionado"
              ) {
                this.$alert(
                  "Error, no se puede encontrar el curso seleccionado"
                );
              }
              if (data.text == "OK") {
                this.respuestaAlerta = [];
                for (var n = 0, len = data.lista.length; n < len; n++) {
                  data.lista[n]["CEAD"] =
                    data.lista[n]["CEAD"] == "null"
                      ? "-"
                      : data.lista[n]["CEAD"];
                  data.lista[n]["Carrera"] =
                    data.lista[n]["Carrera"] == "null"
                      ? "-"
                      : data.lista[n]["Carrera"];
                  data.lista[n]["Cédula"] =
                    data.lista[n]["Cédula"] == "null"
                      ? "-"
                      : data.lista[n]["Cédula"];
                  data.lista[n]["Estado usuario"] = data.lista[n][
                    "Estado usuario"
                  ].includes("DPS")
                    ? "DPS"
                    : data.lista[n]["Estado usuario"];
                  data.lista[n]["Fecha de matrícula"] = this.$moment(
                    data.lista[n]["Fecha de matrícula"]
                  ).format("YYYY/MM/DD - HH:mm:ss");
                  var dat = data.lista;
                  var valJson = Object.keys(dat[n]);
                  for (let k = 0, leng = valJson.length; k < leng; k++) {
                    if (
                      valJson[k].includes("%") ||
                      valJson[k].includes("Nota")
                    ) {
                      dat[n][valJson[k]] =
                        dat[n][valJson[k]] == null ? "" : dat[n][valJson[k]];
                    }
                  }
                  this.respuestaAlerta.push({
                    id: data.lista[n]["_id"],
                    data: data.lista[n]["Respuesta"],
                  });
                }
                this.jsondata = data.lista;
                this.mostrarTable = true;
                if (this.jsondata.length == 0) {
                  this.$alert("este curso no tiene estudiantes matriculados");
                } else {
                  this.listaColumnas = data.visibilidad;
                  this.listaColumnasE = data.dinamicas;

                  for (var k = 0; k < this.listaColumnas.length; k++) {
                    if (this.listaColumnas[k]["valor"] == true) {
                      this.columnasMostrar.push(this.listaColumnas[k]);
                    }
                  }

                  var info = this.jsondata[0];
                  this.cargueCol();
                  this.notas = [];
                  // hasta donde llegan las notas (1 antes)
                  if (Object.keys(info).length > 44) {
                    this.newData = [];
                    let iggg;
                    for (var i = 44; i < Object.keys(info).length; i++) {
                      // los datos antes de los que salen en la tabla
                      /// iggg = this.listaColumnas[i - 26]["valor"];
                      for (var j = 0; j < this.listaColumnas.length; j++) {
                        if (
                          this.listaColumnas[j]["nombre"] ==
                          Object.keys(info)[i]
                        ) {
                          if (
                            this.listaColumnas[j]["nombre"].includes("Nota")
                          ) {
                            this.notas.push(this.listaColumnas[j]["nombre"]);
                          }
                          iggg = this.listaColumnas[j]["valor"];
                        }
                      }
                      var type = "string";
                      if (
                        Object.keys(info)[i].includes("%") ||
                        Object.keys(info)[i].includes("Nota")
                      )
                        type = "number";

                      if (Object.keys(info)[i] != "undefined") {
                        this.newData.push({
                          label: Object.keys(info)[i],
                          field: Object.keys(info)[i],
                          key: i,
                          invisible: !iggg,
                          type: type,
                        });
                      }
                    }
                  }
                  this.nombresCols = [];
                  for (var c = 0; c < this.nombresCols.length; c++) {
                    this.newData.push({
                      label: this.nombresCols[c],
                      field: this.nombresCols[c],
                      key: i,
                      invisible: false,
                    });
                  }
                }
              }
            })
            .catch(function (error) {
              this.$alert("Error " + error.message);
            });
          this.mt = true;
        });
    },

    traeEstados() {
      if (this.jsondata.length == 0) {
        return this.estadoU;
      }
      var fila = this.jsondata[this.indexRRow[0]];
      this.indexRRow[0]++;
      if (fila["Estado usuario"] == "NUEVO") {
        return ["OK"];
      }
      return this.estadoU;
    },

    onChange(newVal, oldVal, row, field) {
      this.textoActionToolBar.push("Guardando " + '"' + newVal + '"');
      var data;
      data = field.label;
      console.log(oldVal);
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "registrar_nota");
          fd.append("estudianteId", row._id);
          fd.append("periodo", this.curso.periodo);
          fd.append("materia", this.curso.materia);
          fd.append("campo", data);
          fd.append("nota", newVal.trim());
          axios.post(host + "/cursos", fd).then((result) => {
            var rem = this.textoActionToolBar.indexOf(
              "Guardando " + '"' + newVal + '"'
            );
            if (result.data.text != "OK") {
              this.$alert("Error al guardar la información de esta nota");
              this.textoActionToolBar.splice(rem, 1);
              this.textoActionToolBar.push(
                "Error al guardar " + '"' + newVal + '"'
              );
              return false;
            }
            if (result.data.text == "OK") {
              this.textoActionToolBar.splice(rem, 1);
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    metodoColor(row, col) {
      var rojos = [
        "NO INGRESA (PLATAFORMA)",
        "NO INGRESA (CORREO)",
        "VERIFICACIÓN CORREO",
        "NO COINCIDE CORREO",
        "MAL MIGRADO",
        "NO LLEGA PROUNAD",
      ];
      var amarillos = ["NI1"];
      var morados = ["DPS"];
      var moradoNuevo = ["NUEVO"];
      var serverNulo = ["SERVER NULO"];
      var verde = ["SOL"];
      var naranja = ["NI2"];
      var azul = ["LAZARO"];

      switch (col.name) {
        case "Usuario":
          if (row.lazaro == true) {
            return {
              "background-color": "#4285f4",
            };
          }
          break;
        case "Estado usuario":
          var rowItemEstadoU = row["Estado usuario"].trim();
          if (rojos.includes(rowItemEstadoU)) {
            return {
              "background-color": "#FF0000",
            };
          }
          if (amarillos.includes(rowItemEstadoU)) {
            return {
              "background-color": "#ffff00",
            };
          }
          if (morados.includes(rowItemEstadoU)) {
            return {
              "background-color": "#9900ff",
            };
          }
          if (moradoNuevo.includes(rowItemEstadoU)) {
            return {
              "background-color": "#c27ba0",
            };
          }
          if (serverNulo.includes(rowItemEstadoU)) {
            return {
              "background-color": "#e06666",
            };
          }
          if (verde.includes(rowItemEstadoU)) {
            return {
              "background-color": "#34a853",
            };
          }
          if (naranja.includes(rowItemEstadoU)) {
            return {
              "background-color": "#ff9900",
            };
          }
          if (azul.includes(rowItemEstadoU)) {
            return {
              "background-color": "#4285f4",
            };
          }
          break;
        case "Respuesta":
          var rowItemRespuesta = row["Respuesta"].trim();

          if (rojos.includes(rowItemRespuesta)) {
            return {
              "background-color": "#FF0000",
            };
          }
          if (amarillos.includes(rowItemRespuesta)) {
            return {
              "background-color": "#ffff00",
            };
          }
          if (morados.includes(rowItemRespuesta)) {
            return {
              "background-color": "#9900ff",
            };
          }
          if (moradoNuevo.includes(rowItemRespuesta)) {
            return {
              "background-color": "#c27ba0",
            };
          }
          if (serverNulo.includes(rowItemRespuesta)) {
            return {
              "background-color": "#e06666",
            };
          }
          if (verde.includes(rowItemRespuesta)) {
            return {
              "background-color": "#34a853",
            };
          }
          if (naranja.includes(rowItemRespuesta)) {
            return {
              "background-color": "#ff9900",
            };
          }
          if (azul.includes(rowItemRespuesta)) {
            return {
              "background-color": "#4285f4",
            };
          }
          break;
        case "Total":
          if (row.Total < 3) {
            return {
              "background-color": "#FF0000",
            };
          }
          break;
        default:
          break;
      }

      return {};
    },

    getLabel(a) {
      this.getLabelColor(a);
      this.numeroCol = a.colPos;
      this.numeroRow = a.rowPos;
      var aver = a.cell.id.split("-")[a.cell.id.split("-").length - 1];
      if (!(aver[0] == "N" && aver[aver.length - 1] == "%")) {
        this.label_ = aver;
      }
    },

    cambioEstadoU(newVal, oldVal, row) {
      var valorDeCelda = newVal.trim();
      this.textoActionToolBar.push(
        "Guardando estadoU " + '"' + valorDeCelda + '"'
      );
      const resObject = this.respuestaAlerta.find(
        (item) => item.id === row._id
      );
      var rta = resObject.data.trim();
      if (
        (rta == "" || rta == "SOL" || rta == "LAZARO" || rta == "OK") &&
        valorDeCelda != "DPS"
      ) {
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then((idToken) => {
            const fd = new FormData();
            fd.append("token", idToken);
            fd.append("tipo", "agregar");
            fd.append("titulo", "Alerta");
            fd.append("materia", this.curso.materia);
            fd.append("periodo", this.curso.periodo);
            fd.append("tipoAlerta", "Alerta");
            fd.append("comentarios", valorDeCelda);
            fd.append("opcionesTipo", "");
            fd.append("estudianteId", row._id);
            axios.post(host + "/alertas", fd).then((result) => {
              let data = result.data.text;
              this.mt = false;
              var rem = this.textoActionToolBar.indexOf(
                "Guardando estadoU " + '"' + valorDeCelda + '"'
              );

              if (data != "OK") {
                this.$alert(
                  "Error al guardar el ajuste de estado de usuario, intentelo nuevamente"
                );
                this.textoActionToolBar.splice(rem, 1);
                this.textoActionToolBar.push(
                  "Error al guardar " + '"' + valorDeCelda + '"'
                );
              } else {
                this.warnigs();
                this.textoActionToolBar.splice(rem, 1);
                this.buscar();
              }
            });
          })
          .catch(function (error) {
            this.$alert("Error " + error.message);
          });
      } else if (rta == "DPS" && valorDeCelda == "DPS") {
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then((idToken) => {
            const fd = new FormData();
            fd.append("token", idToken);
            fd.append("tipo", "agregar");
            fd.append("titulo", "Alerta");
            fd.append("materia", this.curso.materia);
            fd.append("periodo", this.curso.periodo);
            fd.append("tipoAlerta", "Alerta");
            fd.append("comentarios", valorDeCelda);
            fd.append("opcionesTipo", "");
            fd.append("estudianteId", row._id);
            axios.post(host + "/alertas", fd).then((result) => {
              var rem = this.textoActionToolBar.indexOf(
                "Guardando estadoU " + '"' + valorDeCelda + '"'
              );
              let data = result.data.text;
              this.mt = false;
              if (data != "OK") {
                //     this.$alert('Estado de usuario guardado satisfactoriamente')
                // }else{
                this.$alert(
                  "Error al guardar el ajuste de estado de usuario, intentelo nuevamente"
                );
                this.textoActionToolBar.splice(rem, 1);
                this.textoActionToolBar.push(
                  "Error al guardar estadoU" + '"' + valorDeCelda + '"'
                );
                return false;
              } else {
                this.warnigs();
                this.textoActionToolBar.splice(rem, 1);
                this.buscar();
              }
            });
          })
          .catch(function (error) {
            this.$alert("Error " + error.message);
          });
      } else {
        this.$alert("No puedes realizar esta acción");
        return false;
      }
    },

    selectC(a) {
      if (a == "Editar") {
        this.editarColumnas = true;
      }
      if (a == "Eliminar") {
        this.eliminarColumnas = true;
      }
    },

    onSelect() {
      this.onSelected = infoSelected(this.$refs.grid, "_id");
      this.rigthClick = false;
    },

    searchC(a) {
      if (a == "blur") {
        this.blur();
      } else {
        for (let y = 0; y < a.length; y++) {
          var element = a[y];
          if (element.label == "Materias") this.curso.materia = element.value;
        }
        if (element.label == "Periodos") {
          this.curso.periodo = element.value;
        }
      }
    },
    // EVENTOS
    eventosClientes(d) {
      if (d == "refresh" || d == "edit") {
        this.buscar();
      }
      if (d == "clearText") {
        this.textoActionToolBar = [];
      }
    },

    async rMaterias() {
      this.listaCursos = await data.dataList(
        "/cursos",
        "lista",
        "listar",
        "cursos"
      );
      await this.buscar();
    },

    toolBarFooter(a) {
      this.curso = a;
    },
  },
};
</script>


<style scoped>
.table {
  height: 70% !important;
}
</style>