
import firebase from 'firebase'
import { host} from './variables'
import axios from 'axios'
import store from '../../store'


const data = {}
 

 data.getAdminLevel = function() {
     return new Promise(resolve =>
        {
    firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
        const fd = new FormData();
        fd.append('token', idToken);
             axios.post(host + '/infoUsuario', fd).then((result) =>{
                store.state.adminLevel = result.data.isAdmin
                let data = result.data;
                    if (data.text == "Error, dirección IP bloqueada") {
                    this.$alert("IP bloqueada por el administrador");
                    firebase.auth().signOut();
                    } else {
                        resolve(data); 
                    }
            })
            }).catch(function(error) {
            console.log(error)
        })
    })
 }


 // url, result.data.... ,tipo de la url, lo que llega de back, como se llama en store
data.dataList = function(traer, text, tipo, nameStore){
    return new Promise(resolve =>
    {
        // if (store.state[nameStore].length == 0) {
            firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
                const fd = new FormData();
                fd.append('token', idToken);
                fd.append('tipo', tipo);
                axios.post(host + traer, fd).then((result) => {
                    store.state[nameStore] = result.data[text];
                    var dataB = result.data[text]
                    resolve(dataB);
                })
            }).catch(function (error) {
                console.log(error)
            })
        // } else {
        //     resolve(store.state[nameStore]);
        // }
    })
}

export default data